<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <div class="mt-5">
      <div class="title black--text mb-2">List Trainer</div>

      <Breadcrumbs :items="breadcrumbs"/>

      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Trainer"
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              v-on:keyup.enter="fetch()"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn
              depressed
              color="#F05326"
              class="text-capitalize ml-5 white--text rounded-lg"
              @click="showDialogAddTrainer"
            >
              Add Trainer
              <!-- <v-icon right>mdi-filter-variant</v-icon> -->
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list pa-0"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-left white">
                  {{ props.item.fullname }}
                </td>
                <td class="text-left white">
                  {{ props.item.email }}
                </td>
                <td class="text-left white">
                  {{ props.item.phone_number | emptyData }}
                </td>
                <td class="text-left text-capitalize white">
                  {{ props.item.gender.value == 0 ? "Female" : "Male" }}
                </td>
                <td class="text-center white">
                  <div
                    class="font-weight-bold text-center text-capitalize" 
                    :class="props.item.status == 'active' ? 'teal--text' : 'red--text'">{{ props.item.status }}</div>
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'update-trainer',
                          params: { company_id: props.item.company.id, id: props.item.id },
                        }"
                      >
                        <v-icon>mdi-pencil-circle-outline</v-icon>
                      </v-btn> </template
                    ><span>Edit Trainer</span> </v-tooltip
                  ><v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'detail-trainer',
                          params: { company_id: props.item.company.id, id: props.item.id },
                        }"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Trainer</span>
                  </v-tooltip>
                  <v-menu
                    left
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>

                    <v-list dense>
                      <v-list-item 
                        @click.stop="showDialogChangePassword(props.item.fullname, props.item.id)">
                        <v-list-item-title>Change Password</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <DialogAddTrainers
        ref="dialog_add_trainer"
        :company_id="$route.params.id"
        @action="fetch"
      ></DialogAddTrainers>
      <DialogChangePassword ref="dialog_change_password"></DialogChangePassword>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
import DialogAddTrainers from "./componentstrainer/DialogAddTrainers.vue";
import DialogChangePassword from "./componentstrainer/DialogChangePassword.vue";
export default {
  components: {
    Pagination,
    Breadcrumbs,
    Snackbar,
    DialogAddTrainers,
    DialogChangePassword,
  },
  data() {
    return {
      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      headers: [
        { text: "Trainer Name", align: "start", value: "name", sortable: false },
        { text: "Email", align: "start", value: "email", sortable: false },
        { text: "Phone Number", align: "start", value: "phone_number", sortable: false },
        { text: "Gender", align: "start", value: "gender.text", sortable: false },
        { text: "Status", align: "center", value: "status", sortable: false },
        { text: "Action", align: "center", value: "action", sortable: false },
      ],
      breadcrumbs: [
        {
          text: "List Company",
          disabled: false,
          href: "/company",
        },
        {
          text: "List Trainer",
          disabled: true,
          href: "/company/trainer-list/",
        },
      ],
      items: {},
      member: {},
      form: {
        member_id: "",
      },
      searching: "",
      process: {
        run: false,
      },
      dialog: {
        detail: false,
        add_trainer: false,
      },
    };
  },
  props: {
    company_id: String,
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
  },
  created() {
    this.fetch();
  },
  mounted() {},
  methods: {
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    filter() {
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging) {
      this.process.run = true;
      get(`trainer/v1/list`, {
        params: {
          company_id: this.$route.params.id,
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          q: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
      });
    },
    details(item) {
      this.member = item;
      this.dialog.detail = true;
    },
    showDialogAddTrainer() {
      this.$refs.dialog_add_trainer.showDialog();
    },
    showDialogChangePassword(name, id) {
      this.$refs.dialog_change_password.showDialog(name, id);
    },
  },
};
</script>

<style></style>
