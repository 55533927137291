var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"title black--text mb-2"},[_vm._v("List Trainer")]),_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('v-text-field',{attrs:{"solo":"","filled":"","dense":"","flat":"","hide-details":true,"placeholder":"Search Trainer","autocomplete":"off","readonly":_vm.process.run,"clearable":"","color":"#F05326","prepend-inner-icon":"mdi-magnify"},on:{"click:clear":function($event){_vm.searching = ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetch()}},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1)]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex float-right"},[_c('v-btn',{staticClass:"text-capitalize ml-5 white--text rounded-lg",attrs:{"depressed":"","color":"#F05326"},on:{"click":_vm.showDialogAddTrainer}},[_vm._v(" Add Trainer ")])],1)])],1),_c('div',{staticClass:"mt-3 mb-8"},[_c('v-skeleton-loader',{staticClass:"ma-auto",attrs:{"loading":_vm.$store.state.process.run,"tile":false,"transition":"scale-transition","type":"table-tbody"}},[_c('v-data-table',{staticClass:"table-list pa-0",attrs:{"hide-default-footer":"","fixed-header":"","loading":_vm.$store.state.process.run || _vm.process.run,"headers":_vm.headers,"items":_vm.items.list,"items-per-page":_vm.items.limit,"page":_vm.items.page,"server-items-length":_vm.items.total,"item-key":"id","footer-props":{
            itemsPerPageText: 'Data per halaman',
            'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
          }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.items, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.items, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.items, "page", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
          var props = ref.props;
return [_c('Pagination',{attrs:{"model":props},on:{"reload":function($event){return _vm.fetch(_vm.page)},"changeLimit":function($event){_vm.limit = $event;
                _vm.fetch();},"changePage":function($event){_vm.page = $event;
                _vm.fetch($event);}}})]}},{key:"item",fn:function(props){return [_c('tr',{staticClass:"mx-3 px-3 box-shadow"},[_c('td',{staticClass:"text-left white"},[_vm._v(" "+_vm._s(props.item.fullname)+" ")]),_c('td',{staticClass:"text-left white"},[_vm._v(" "+_vm._s(props.item.email)+" ")]),_c('td',{staticClass:"text-left white"},[_vm._v(" "+_vm._s(_vm._f("emptyData")(props.item.phone_number))+" ")]),_c('td',{staticClass:"text-left text-capitalize white"},[_vm._v(" "+_vm._s(props.item.gender.value == 0 ? "Female" : "Male")+" ")]),_c('td',{staticClass:"text-center white"},[_c('div',{staticClass:"font-weight-bold text-center text-capitalize",class:props.item.status == 'active' ? 'teal--text' : 'red--text'},[_vm._v(_vm._s(props.item.status))])]),_c('td',{staticClass:"text-center white"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue","to":{
                        name: 'update-trainer',
                        params: { company_id: props.item.company.id, id: props.item.id },
                      }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Trainer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"warning","to":{
                        name: 'detail-trainer',
                        params: { company_id: props.item.company.id, id: props.item.id },
                      }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail Trainer")])]),_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.showDialogChangePassword(props.item.fullname, props.item.id)}}},[_c('v-list-item-title',[_vm._v("Change Password")])],1)],1)],1)],1)])]}}])})],1)],1),_c('DialogAddTrainers',{ref:"dialog_add_trainer",attrs:{"company_id":_vm.$route.params.id},on:{"action":_vm.fetch}}),_c('DialogChangePassword',{ref:"dialog_change_password"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }